import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { ACCOUNTDETAIL } from "Constants/QueriesKeys";
import { handleError, http } from "Utils/Http/Http";
import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import {
  DataGrid,
  ResourceListSkeleton,
  IconBtnBase,
  ResourcesList,
  ConfirmationDialog,
  Btn,
  InputController,
} from "Components";
import Yup from "Utils/Yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// import Dialog from "Components/Dialogs/Dialog";
// import yup from "Utils/Yup";

const styles = {
  tableSize: {
    // height: 650,
    // width: "100%",
  },
  title: {
    color: "#797979",
    fontSize: "14px",
  },
  subTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "16px",
  },
};

const schema = Yup.object().shape({
  name: Yup.string().required(),
});

// Component goes here
const CategoryDetailPage = () => {
  const { accountId } = useParams();
  // const category = categoryKeymap[accountId];
  const { t } = useTranslation();
  const columns = [
    {
      field: "name",
      headerName: t("category.subcategory"),
      minWidth: 800,
      editable: false,
    },
    {
      field: "action",
      headerName: t("category.actions"),
      minWidth: 160,
      editable: false,
      renderCell: (row) => {
        return (
          <div className="flex items-center">
            <IconBtnBase
              title={t("common.edit")}
              onClick={() => {
                showEdit(row?.row);
              }}
              size="large"
            >
              <EditIcon />
            </IconBtnBase>
            {/* <IconBtnBase
              title={t("common.delete")}
              onClick={() => setShowDeleteDialog(true)}
              size="large"
            >
              <DeleteOutlineIcon />
            </IconBtnBase> */}
          </div>
        );
      },
    },
  ];
  const [subcategories, setSubcategories] = useState(null);
  const [categories, setCategories] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit, setError, reset } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    accountDetailQuery();
  }, []);

  const accountDetailQuery = () => {
    http.get(`/request-categories`, {}).then(({ data }) => {
      let category =
        data?.data?.find((f) => f?.id === parseInt(accountId)) ?? [];
      setSubcategories(category?.subCategories);
    });
  };

  const showEdit = (row) => {
    console.log(row);
    reset({
      name: row?.name,
    });
    setSelectedId(row?.id);
    setShowModal(true);
  };

  // if (accountDetailQuery.isLoading) {
  //     return <ResourceListSkeleton hasBreadcrumbs />;
  // }

  const deleteSubCategory = () => {
    setShowDeleteDialog(false);
    setShowSuccessMsg("Category Deleted Successfully");
  };

  const handleAddUpdateSubCategory = (values) => {
    // setProgress(CREATE_CAT_BTN, true);
    setIsLoading(true);
    setShowSuccessMsg(false);
    try {
      const formData = { ...values, request_category_id: accountId };
      if (selectedId) {
        http
          .put(`/request-sub-categories/${selectedId}`, formData)
          .then(({ data }) => {
            if (data?.data?.id) {
              reset({ name: "" });
              setSelectedId(false);
              accountDetailQuery();
              setShowSuccessMsg("Category Updated Successfully");
              setIsLoading(false);
            }
            setShowModal(false);
          });
      } else {
        setIsLoading(true);
        http.post(`/request-sub-categories`, formData).then(({ data }) => {
          if (data?.data?.id) {
            reset({ name: "" });
            setSelectedId(false);
            accountDetailQuery();
            setShowSuccessMsg("Category Created Successfully");
          }
          setShowModal(false);
        });
      }
      setIsLoading(false);
    } catch (error) {
      handleError(error, { setError });
    }
    // setProgress(CREATE_CAT_BTN, false);
    // setIsLoading(false);
  };

  return (
    <>
      <Grid xs={12}>
        <ResourcesList
          BeforeHeader={
            <div className="flex justify-between items-center">
              <Breadcrumbs>
                <Link component={NavLink} to="/app/category">
                  {t("category.title")}
                </Link>
                <span>{t("category.subtitle")}</span>
                {/* <Typography color="textPrimary"># sss</Typography> */}
              </Breadcrumbs>
              <div className="flex">
                <IconBtnBase
                  onClick={() => {
                    setShowModal(true);
                  }}
                  size="large"
                  title={t("category.addNewSubCategory")}
                >
                  <AddIcon />
                </IconBtnBase>
                <IconBtnBase
                  onClick={() => {
                    accountDetailQuery();
                  }}
                  size="large"
                  title="Refresh Data"
                >
                  <RefreshIcon />
                </IconBtnBase>
              </div>
            </div>
          }
          Section={
            <div className="mt-8">
              <Box sx={styles.tableSize}>
                <Paper>
                  <div
                    style={{ height: 600, width: "100%", overflowY: "auto" }}
                  >
                    <DataGrid
                      pagination
                      // rowCount={accountQuery.data?.meta.total}
                      paginationMode="server"
                      // onPageChange={(newPage) => { setPage(newPage + 1); console.log(newPage) }}
                      // loading={accountQuery.isFetching}
                      rows={subcategories || []}
                      columns={columns}
                      // pageSize={accountQuery.data?.meta.per_page}
                    />
                  </div>
                </Paper>
              </Box>
            </div>
          }
        />
      </Grid>
      <div className="pb-4 ">
        <Snackbar
          open={Boolean(showSuccessMsg)}
          autoHideDuration={6000}
          onClose={() => setShowSuccessMsg(false)}
        >
          <Alert
            onClose={() => setShowSuccessMsg(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            {showSuccessMsg}
          </Alert>
        </Snackbar>
      </div>
      {showDeleteDialog && (
        <ConfirmationDialog
          isOpen={showDeleteDialog}
          title={"Delete Category"}
          text="Are you sure you want to delete this Category ?"
          onReject={() => setShowDeleteDialog(false)}
          onResolve={() => deleteSubCategory()}
          actionBtn={t("common.delete")}
        />
      )}
      <Dialog
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("category.addNewSubCategory")}
        </DialogTitle>
        <form onSubmit={handleSubmit(handleAddUpdateSubCategory)}>
          <DialogContent>
            <InputController
              control={control}
              name="name"
              label={t("category.subtitle")}
              placeholder={t("category.subtitle")}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              varient="outlined"
              onClick={() => {
                reset({ name: "", requires_confirmation_code: false });
                setShowModal(false);
              }}
            >
              {t("common.cancel")}
            </Button>

            <Btn
              style={{
                pointerEvents: isLoading ? "none" : "auto",
                width: "100px",
              }}
              type="submit"
              // name={CREATE_CAT_BTN}
            >
              {!isLoading ? (
                <span>{t("common.save")}</span>
              ) : (
                <span style={{ position: "relative", top: "3px" }}>
                  <CircularProgress color="white" size={20} />
                </span>
              )}
            </Btn>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CategoryDetailPage;
