import './index.css';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { setLocale } from 'yup';
import SignInPage from 'Pages/Auth/SignInPage';
import SendResetLinkPage from 'Pages/Auth/SendResetLinkPage';
import ResetPasswordPage from 'Pages/Auth/ResetPasswordPage';
import { useEffect, useState } from 'react';
import { http } from 'Utils/Http/Http';
import App from 'App';
import { AuthContext } from 'Contexts/AuthContext';
import GuardedRoute from 'Utils/Guards/GuardedRoute';
import AuthGuard from 'Utils/Guards/AuthGuard';
import GuestGuard from 'Utils/Guards/GuestGuard';
import { LinearProgress } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

setLocale({
  mixed: {
    required: 'required',
  },
  string: {
    required: 'required',
  },
});

function Entry() {
  const { t } = useTranslation();
  const [isFetchingUserData, setIsFetchingUserData] = useState(true);
  const [auth, setAuth] = useState({
    user: null,
    fetchUserProfile: () => {
      return http
        .get('/auth ')
        .then((response) => {
          setAuth({ ...auth, user: response.data.data });
        })
        .catch(() => {
          setAuth({ ...auth, user: null });
        });
    },
    refreshCsrf: () => {
      return http.get(`/sanctum/csrf-cookie`, {
        baseURL: process.env.REACT_APP_BASE_URL,
      });
    },
  });

  useEffect(() => {
    setIsFetchingUserData(true);
    auth.fetchUserProfile().finally(() => setIsFetchingUserData(false));
    auth.refreshCsrf();
  }, []);

  return (
    <AuthContext.Provider value={auth}>
      {isFetchingUserData ? (
        <div className="h-screen flex justify-center items-center">
          <LinearProgress variant="query" className="w-72" />

        </div>
      ) : (
        <>
          <Switch>
            <Route exact path="/">
              <Redirect to="/sign-in" />
            </Route>
            <GuardedRoute
              guards={[GuestGuard]}
              exact
              path="/sign-in"
              component={SignInPage}
            />
            <Route exact path="/forgot-password" component={SendResetLinkPage} />
            <Route exact path="/reset-password" component={ResetPasswordPage} />
            <GuardedRoute guards={[AuthGuard]} path="/app" component={App} />
            {/* <Route path="/app" component={App} /> */}
          </Switch>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            className="toast-container"
            toastClassName="dark-toast"
            pauseOnHover />
        </>
      )}
    </AuthContext.Provider>
  );
}

export default Entry;
