import { create } from "react-modal-promise";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BtnBase from "../Buttons/BtnBase";
import { Btn } from "Components";

function ConfirmationDialog({
  isOpen,
  title,
  text,
  onReject,
  onResolve,
  actionBtn,
}) {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onReject} fullWidth maxWidth="sm">
      <DialogContent>
        <div className="space-y-10">
          <div className="pt-3">
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body1" className="mt-1">
              {text}
            </Typography>
          </div>

          <div className="flex justify-end pb-1.5 pr-1">
            <BtnBase
              variant="text"
              onClick={onReject}
              fullWidth={false}
              color="secondary"
            >
              {t("common.cancel")}
            </BtnBase>
            <Btn variant="contained" onClick={onResolve} fullWidth={false}>
              {actionBtn}
            </Btn>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmationDialog;
