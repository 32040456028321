import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  CircularProgress,
} from "@mui/material";

import { Box } from "@mui/system";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import { useQuery } from "react-query";
import { ACCOUNTS } from "Constants/QueriesKeys";
import { handleError, http } from "Utils/Http/Http";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  DataGrid,
  ResourceListSkeleton,
  IconBtnBase,
  ResourcesList,
  Btn,
  setProgress,
  InputController,
  ConfirmationDialog,
  SelectController,
  AbsoluteCenterLoader,
} from "Components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Yup from "Utils/Yup";
import { Countries } from "Constants/CountryCodes";

const CountriesPhoneCode = Countries.map((c) => ({
  id: c.Iso2,
  name: c.Iso2 + " " + c.Dial,
}));

const styles = {
  tableSize: {
    height: 650,
    width: "100%",
  },
};
const CREATE_CAT_BTN = "CREATE_CAT_BTN";
const schema = Yup.object().shape({
  name: Yup.string().required("required"),
  phone_country_code: Yup.string().required("requiredSelect"),
  phone_number: Yup.string().required("required"),
  email: Yup.string().email().required("required"),
  company_name: Yup.string().required("required"),
  subdomain: Yup.string().test("alphabets", "subdomain", (value) => {
    return /^[A-Za-z]+$/.test(value);
  }),
});

// Component goes here
const AddAccount = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { accountId } = useParams();
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(false);

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log(errors);

  useEffect(() => {
    if (accountId && accountId !== undefined) getData();
  }, [accountId]);

  const getData = () => {
    try {
      http.get(`/tenant-register/${accountId}`).then(({ data }) => {
        // let obj = data?.data.find((f) => f.id === accountId);
        setSelectedData(data?.data);
        let obj = data?.data;
        reset({
          name: obj?.name ?? "",
          phone_number: obj?.phone_number ?? "",
          email: obj?.email ?? "",
          company_name: obj?.company_name ?? "",
          subdomain: obj?.id ?? "",
          phone_country_code: obj?.phone_number_code ?? "",
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddAccount = (values) => {
    // setProgress(CREATE_CAT_BTN, true);
    setShowSuccessMsg(false);
    setShowErrorMsg(false);
    try {
      const form = { ...values };
      setIsLoading(true);
      if (accountId) {
        let id = form.subdomain;
        delete form.subdomain;
        http
          .put(`/tenant-register/${id}`, form)
          .then(({ data }) => {
            if (data?.data?.id) {
              reset({
                name: "",
                phone_number: "",
                email: "",
                company_name: "",
                subdomain: "",
                phone_country_code: "",
              });
              setIsLoading(false);
              setShowSuccessMsg(
                accountId
                  ? "Account Updated Successfully"
                  : "Account Created Successfully"
              );
              setSelectedData(false);
              setTimeout(() => {
                history.push("/app/accounts");
              }, 2000);
            }
          })
          .catch((e) => {
            setTimeout(() => {
              history.push("/app/accounts");
            }, 4000);
            setShowErrorMsg("Something went Wrong. Please Try Again");
          });
      } else {
        http
          .post(`/tenant-register/`, form)
          .then((data) => {
            setIsLoading(false);
            reset({
              name: "",
              phone_number: "",
              email: "",
              company_name: "",
              subdomain: "",
            });
            setShowSuccessMsg("Account Created Successfully");
            setSelectedData(false);
            setTimeout(() => {
              history.push("/app/accounts");
            }, 2000);
            // if (data?.data?.id) {
            //   reset({
            //     name: "",
            //     phone_number: "",
            //     email: "",
            //     company_name: "",
            //     subdomain: "",
            //   });
            //   setShowSuccessMsg("Account Created Successfully");
            //   setSelectedData(false);
            //   setTimeout(() => {
            //     history.push("/app/accounts");
            //   }, 2000);
            // }
          })
          .catch((e) => {
            setIsLoading(false);
            setShowErrorMsg(
              e?.response?.data?.message?.includes("Duplicate entry")
                ? "Company Already Exists. "
                : "Something went Wrong. Please Try Again"
            );
            setTimeout(() => {
              history.push("/app/accounts");
            }, 4000);
          });
      }
    } catch (error) {
      handleError(error, { setError });
    }
    // setProgress(CREATE_CAT_BTN, false);
  };

  return (
    <>
      <h3 className="mb-4">
        <ArrowBackIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/app/accounts");
          }}
        />{" "}
        &nbsp; {accountId ? "Update" : "Add New"}
      </h3>
      <Card className="p-4" style={{ position: "relative" }}>
        {isLoading && <AbsoluteCenterLoader />}
        <Box style={{ width: "50%" }}>
          <form onSubmit={handleSubmit(handleAddAccount)}>
            <div className="mb-3">
              <InputController
                control={control}
                name="name"
                label={"Enter User Name"}
                placeholder={"Enter Name"}
              />
            </div>
            <div className="mb-3">
              <label style={{ fontSize: "14px" }}>Enter Phone No.</label>
              <div style={{ display: "flex", marginTop: "8px" }}>
                <div style={{ minWidth: "150px" }}>
                  <SelectController
                    name="phone_country_code"
                    label={""}
                    control={control}
                    options={CountriesPhoneCode || []}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    size="large"
                    style={{ padding: "15px", width: "100%" }}
                  />
                </div>
                <div style={{ flex: 2, marginLeft: "15px" }}></div>
                <InputController
                  control={control}
                  name="phone_number"
                  placeholder={"Phone Number"}
                />
              </div>
            </div>
            <div className="mb-3">
              <InputController
                control={control}
                name="email"
                label={"Enter Email ID"}
                placeholder={"Email ID"}
              />
            </div>
            <div className="mb-3">
              <InputController
                control={control}
                name="company_name"
                label={"Enter Company Name"}
                placeholder={"Company Name"}
              />
            </div>
            <div className="mb-3">
              <InputController
                control={control}
                name="subdomain"
                label={"Enter Company ID"}
                placeholder={"Company ID"}
                disabled={accountId}
              />
            </div>
            <div className="flex">
              <Button
                color="primary"
                onClick={() => {
                  history.push("/app/accounts");
                }}
              >
                {t("common.cancel")}
              </Button>

              <Button type="submit" name={CREATE_CAT_BTN} variant="contained">
                {!isLoading ? (
                  <span>{t("common.save")}</span>
                ) : (
                  <span style={{ position: "relative", top: "3px" }}>
                    {t("common.saving") + "..."}
                  </span>
                )}
              </Button>
            </div>
          </form>
        </Box>
      </Card>
      {showSuccessMsg ? (
        <div className="pb-4 ">
          <Alert severity="success">{showSuccessMsg}</Alert>
        </div>
      ) : null}
      {showErrorMsg ? (
        <div className="pb-4 ">
          <Alert severity="error">{showErrorMsg}</Alert>
        </div>
      ) : null}
    </>
  );
};

export default AddAccount;
