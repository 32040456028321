import React from 'react';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';

function AbsoluteCenterLoader() {
  return (
    <Box
      position='absolute'
      width='100%'
      height='100%'
      top={'0'}
      left={'0'}
      right={'0'}
      bottom={'0'}
      backgroundColor='rgba(0,0,0,0.02)'
      zIndex='1'
      className='flex align-center justify-center'
    >
      <span
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress style={{ marginRight: '12px' }} />
        <span className='loading'>Submitting form ...</span>
      </span>
    </Box>
  );
}

export default AbsoluteCenterLoader;
