import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import NotificationsTwoTone from "@mui/icons-material/NotificationsTwoTone";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ListIcon from "@mui/icons-material/List";

export const NavLinks = [
  // { name: 'Home', path: '/app/dashboard', current: true, Icon: HomeRoundedIcon },
  {
    name: "Company Accounts",
    path: "/app/accounts",
    current: true,
    Icon: HomeWorkIcon,
  },
  //   {
  //     name: "Push Notification",
  //     path: "/app/pushNotification",
  //     current: true,
  //     Icon: NotificationsTwoTone,
  //   },
  {
    name: "Category",
    path: "/app/category",
    current: true,
    Icon: ListIcon,
  },
  // { name: 'Funds', path: '/app/funds', current: true, Icon: BusinessIcon },
  // { name: 'Bank Accounts', path: '/app/bank-accounts', current: false, Icon: AccountBalanceRoundedIcon },
];

export const ConfigNavLinks = [
  {
    name: "Notifications",
    path: "/app/pushNotification",
    current: true,
    Icon: NotificationsTwoTone,
  },
];
