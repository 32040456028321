import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from 'Assets/logo.svg';
import { Card, CardContent, Typography, Divider, Container } from '@mui/material';
import ResetPassword from './Components/ResetPassword';
import { http } from 'Utils/Http/Http';

const resetPassword = (values) =>
    http.post('/api/reset-password', values, {
        baseURL: process.env.REACT_APP_BASE_URL,
    });

export default function ResetPasswordPage() {
    const { t } = useTranslation();

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-2 md:px-0">
            <img className="w-auto h-16 mx-auto mb-5" src={logo} alt="Aseel Logo" />
            <Container className="max-w-md">
                <Card>
                    <div className="px-5 py-5">
                        <Typography variant="h5">{t('password.resetPassword')}</Typography>
                    </div>
                    <Divider />
                    <CardContent>
                        <div className="space-y-4">
                            <ResetPassword resetPassword={resetPassword} buttonClassName="flex justify-end mt-5" />
                        </div>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
}
