import "./index.css";
import { setLocale } from "yup";
import { useMemo } from "react";
import React from "react";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ModalContainer from "react-modal-promise";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import SelectController from "Components/FormControls/Controllers/SelectController";
import { useForm } from "react-hook-form";
import DatePickerController from "Components/FormControls/Controllers/DatePickerController";
import Btn from "Components/Buttons/Btn";
import Yup from "Utils/Yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Entry from "./Entry";

setLocale({
  mixed: {
    required: "required",
  },
  string: {
    required: "required",
  },
});

const schema = Yup.object().shape({
  date: Yup.mixed().required(),
  select: Yup.string().required(),
});

function App() {
  const { i18n } = useTranslation();

  // Create rtl cache
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: i18n.language === "ar" ? [rtlPlugin] : "",
  });

  const form = useForm({
    defaultValues: {
      date: "2021-05-01",
    },
    resolver: yupResolver(schema),
  });
  console.log(i18n.language, "langs");
  document.body.dir = "ltr";
  const theme = useMemo(
    () =>
      responsiveFontSizes(
        createTheme({
          // direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
          palette: {
            primary: {
              light: "#4fc18c",
              main: "#03a65a",
              dark: "#039551",
              bg: "rgba(229, 252, 242, 1)",
              contrastText: "#fff",
            },
            secondary: {
              light: "#676767",
              main: "#262626",
              dark: "#222222",
              contrastText: "#fff",
            },
            tertiary: {
              main: "#FFE446",
              contrastText: "#132A46",
            },
            white: {
              main: "#FFFFFF",
            },
            warning: {
              main: "#F8AA35",
              contrastText: "#fff",
            },
          },
          spacing: (factor) => `${0.25 * factor}rem`,
        })
      ),
    [i18n.language]
  );

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <BrowserRouter basename="/">
            <Entry />
          </BrowserRouter>
          {/* <form className="px-10" onSubmit={form.handleSubmit(() => {})}>
                        <div>Hello, World!</div>
                        <div className="flex space-s-5">
                            <div className="w-1/2">
                                <SelectController
                                    control={form.control}
                                    name="select"
                                    options={[
                                        {
                                            value: 'hi',
                                            label: 'whit',
                                        },
                                    ]}
                                />
                            </div>
                            <DatePickerController control={form.control} name="hello" />
                        </div>
                        <div>
                            <Btn name="date" className="mt-4" type="submit">
                                Hi
                            </Btn>
                        </div>
                    </form> */}
        </LocalizationProvider>
        <ModalContainer />
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
