import { useTheme } from "@mui/system";
import React from "react";
import { NavLink } from "react-router-dom";

const useStyles = (theme) => ({
  navLink: {
    // backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[400],
    display: "flex",
    alignItems: "center",
    borderRadius: "0.375rem",
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  },
  navLinkActive: {
    background: "rgba(229, 252, 242, 1)",
    color: theme.palette.primary.main,
  },
});

function Sidebar({ links, settings }) {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <nav aria-label="Sidebar" className="sticky divide-y divide-gray-300 top-4">
      <div className="pb-8 space-y-1">
        {links.map(({ current, Icon, name, path }) => (
          <NavLink
            key={name}
            to={path}
            activeStyle={styles.navLinkActive}
            style={styles.navLink}
          >
            <Icon
              className="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
              aria-hidden="true"
            />
            <span className="truncate">{name}</span>
          </NavLink>
        ))}
      </div>

      <div className="pb-8 space-y-1">
        <p
          style={{
            color: "#aaa",
            fontSize: "14px",
            fontWeight: "500",
            margin: "8px",
          }}
        >
          Settings
        </p>
        {settings.map(({ current, Icon, name, path }) => (
          <NavLink
            key={name}
            to={path}
            activeStyle={styles.navLinkActive}
            style={styles.navLink}
          >
            <Icon
              className="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
              aria-hidden="true"
            />
            <span className="truncate">{name}</span>
          </NavLink>
        ))}
      </div>
    </nav>
  );
}

export default Sidebar;
