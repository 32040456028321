import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { handleError } from "Utils/Http/Http";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import {
  Btn,
  InputController,
  setProgress,
  SelectController,
  ResourcesList,
} from "Components";
import Yup from "Utils/Yup";
import { useParams } from "react-router";
import { http } from "Utils/Http/Http";
import Card from "@mui/material/Card";
import { useQuery } from "react-query";
import { ACCOUNTS } from "Constants/QueriesKeys";
import ImageController from "Components/FormControls/Controllers/ImageController";

const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";

const schema = Yup.object().shape({
  tenant_id: Yup.string().required("required"),
  // role: Yup.string().required('required'),
  title: Yup.string().required("required"),
  text: Yup.string().required("required"),
  image: Yup.array(),
});

function CreatePushNotification() {
  const { t } = useTranslation();
  const { fundId } = useParams();
  const form = useForm({
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, setError, reset, errors } = form;

  const [preview, setPreview] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const accountQuery = useQuery([ACCOUNTS], () =>
    http.get(`/tenants`).then(({ data }) => data)
  );

  const handleFormSubmission = async (values) => {
    console.log(values);
    setProgress(CREATE_PAYOUT_BTN, true);
    //convert to form data

    const formData = new FormData();
    formData.append("tenant_id", values.tenant_id);
    formData.append("role", "ADMIN");
    formData.append("title", values.title);
    formData.append("text", values.text);
    for (var i = 0; i < values?.image?.length; i++) {
      formData.append("image", values.image[i]);
    }
    try {
      await http.post(`/push-notifications/send`, formData);
      reset({});
    } catch (error) {
      handleError(error, { setError });
    }
    setProgress(CREATE_PAYOUT_BTN, false);
  };

  return (
    <ResourcesList
      BeforeHeader={
        <>
          <Typography variant="h6" color="textPrimary">
            Push Notification
          </Typography>
        </>
      }
      Section={
        <Card variant="outlined">
          <form
            onSubmit={handleSubmit(handleFormSubmission)}
            className="space-y-3 py-5 m-5 col-span-4 w-6/12"
          >
            <SelectController
              control={control}
              name="tenant_id"
              size="small"
              label={t("notification.company")}
              options={accountQuery.data?.data || []}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.company_name}
              className="col-span-4"
            />
            {/* <SelectController
              control={control}
              name="role"
              label={t('notification.user')}
              options={[
                { id: 1, name: 1 },
                { id: 3, name: 2 },
              ]}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              className="col-span-4"
            /> */}
            <InputController
              size="small"
              control={control}
              name="title"
              label={t("notification.mTitle")}
            />
            <InputController
              multiline
              size="small"
              rows={4}
              control={control}
              name="text"
              label={t("notification.message")}
            />
            <h6 className="labelForm">{t("notification.attachment")}</h6>
            <ImageController
              name="image"
              filesLimit={5}
              acceptedFiles
              form={form}
              errors={errors}
            />
            <div className="flex pt-3">
              <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN}>
                {t("notification.submit")}
              </Btn>
            </div>
          </form>
        </Card>
      }
    />
  );
}

export default CreatePushNotification;
