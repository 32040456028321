import React, { useState } from 'react';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Snackbar,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { useQuery } from 'react-query';
import { ACCOUNTS } from 'Constants/QueriesKeys';
import { handleError, http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  DataGrid,
  ResourceListSkeleton,
  IconBtnBase,
  ResourcesList,
  Btn,
  setProgress,
  InputController,
  ConfirmationDialog,
  BtnBase,
  CheckboxController,
} from 'Components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Yup from 'Utils/Yup';

const styles = {
  tableSize: {
    height: 650,
    width: '100%',
  },
};
const CREATE_CAT_BTN = 'CREATE_CAT_BTN';
const schema = Yup.object().shape({
  name: Yup.string().required(),
});

// Component goes here
const CategoryPage = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { control, handleSubmit, setError, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const accountQuery = useQuery(
    [ACCOUNTS, { page }],
    () =>
      http.get(`/request-categories`).then(({ data }) => {
        setCategories(data?.data ?? []);
        return categories;
      }),
    [page]
  );
  const handleAddUpdateCategory = (values) => {
    // setProgress(CREATE_CAT_BTN, true);
    setIsLoading(true);
    setShowSuccessMsg(false);
    try {
      const formData = { ...values };
      if (selectedId) {
        http
          .put(`/request-categories/${selectedId}`, formData)
          .then(({ data }) => {
            if (data?.data?.id) {
              reset({ name: '', requires_confirmation_code: false });
              setSelectedId(false);
              accountQuery.refetch();
              setShowSuccessMsg('Category Updated Successfully');
              setIsLoading(false);
            }
            setShowModal(false);
          });
      } else {
        setIsLoading(true);
        http.post(`/request-categories/`, formData).then(({ data }) => {
          if (data?.data?.id) {
            reset({ name: '', requires_confirmation_code: false });
            setSelectedId(false);
            accountQuery.refetch();
            setShowSuccessMsg('Category Created Successfully');
          }
          setShowModal(false);
        });
        setIsLoading(false);
      }
    } catch (error) {
      handleError(error, { setError });
    }
    // setProgress(CREATE_CAT_BTN, false);
    // setIsLoading(false);
  };

  const showEdit = (row) => {
    reset({
      name: row?.name,
      requires_confirmation_code: row?.requires_confirmation_code,
    });
    setSelectedId(row?.id);
    setShowModal(true);
  };

  const deleteCategory = () => {
    setShowDeleteDialog(false);
    setShowSuccessMsg('Category Deleted Successfully');
  };

  const columns = [
    {
      field: 'name',
      headerName: t('category.name'),
      minWidth: 400,
      editable: false,
    },
    {
      field: 'requires_confirmation_code',
      headerName: t('category.confirmCode'),
      minWidth: 300,
      editable: false,
      renderCell: (row) => {
        return (
          <span>
            {row?.row?.requires_confirmation_code === 1 ? 'Yes' : 'No'}
          </span>
        );
      },
    },
    {
      field: 'action',
      headerName: t('category.actions'),
      minWidth: 160,
      editable: false,
      renderCell: (row) => {
        return (
          <div className="flex items-center">
            <IconBtnBase
              onClick={() => {
                handleViewTransactionButtonClick(row);
              }}
              size="large"
            >
              <VisibilityIcon />
            </IconBtnBase>
            <IconBtnBase
              title={t('common.edit')}
              onClick={() => {
                showEdit(row?.row);
              }}
              size="large"
            >
              <EditIcon />
            </IconBtnBase>
            {/* <IconBtnBase
              title={t("common.delete")}
              onClick={() => setShowDeleteDialog(true)}
              size="large"
            >
              <DeleteOutlineIcon />
            </IconBtnBase> */}
          </div>
        );
      },
    },
  ];

  // Handlers
  const handleViewTransactionButtonClick = (row) => {
    history.push(`/app/category/${row?.id}`);
  };

  // if (accountQuery.isLoading) {
  //     return <ResourceListSkeleton />;
  // }

  return (
    <div>
      <ResourcesList
        headerProps={{
          title: <p style={{ fontSize: '20px' }}>{t('category.title')}</p>,
          EndSide: (
            <div className="flex">
              <IconBtnBase
                onClick={() => {
                  setShowModal(true);
                }}
                size="large"
                title={t('category.addNewCategory')}
              >
                <AddIcon />
              </IconBtnBase>
              <IconBtnBase
                onClick={() => {
                  accountQuery.refetch();
                }}
                size="large"
                title="Refresh Data"
              >
                <RefreshIcon />
              </IconBtnBase>
            </div>
          ),
        }}
        Section={
          <div className="mt-8">
            <Box sx={styles.tableSize}>
              <Paper>
                <div style={{ height: 600, width: '100%', overflowY: 'auto' }}>
                  <DataGrid
                    // pagination
                    // rowCount={accountQuery.data?.meta.total}
                    // paginationMode="server"
                    // onPageChange={(newPage) => {
                    //   setPage(newPage + 1);
                    // }}
                    loading={accountQuery.isFetching}
                    rows={categories || []}
                    columns={columns}
                    // page={page - 1}
                    // pageSize={accountQuery.data?.meta.per_page}
                  />
                </div>
              </Paper>
            </Box>
          </div>
        }
      />
      <Dialog
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {selectedId
            ? t('category.updateCategory')
            : t('category.addNewCategory')}
        </DialogTitle>
        <form onSubmit={handleSubmit(handleAddUpdateCategory)}>
          <DialogContent>
            <InputController
              control={control}
              name="name"
              label={t('category.title')}
              placeholder={t('category.addNewCategory')}
            />
            <div className="my-4">
              <CheckboxController
                control={control}
                name="requires_confirmation_code"
                label={t('category.confirmCode')}
                defaultValue={false}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              varient="outlined"
              onClick={() => {
                reset({ name: '', requires_confirmation_code: false });
                setShowModal(false);
                setSelectedId(false);
              }}
            >
              {t('common.cancel')}
            </Button>

            <Btn
              style={{
                pointerEvents: isLoading ? 'none' : 'auto',
                width: '100px',
              }}
              type="submit"
              // name={CREATE_CAT_BTN}
            >
              {!isLoading ? (
                <span>{t('common.save')}</span>
              ) : (
                <span style={{ position: 'relative', top: '3px' }}>
                  <CircularProgress color="white" size={20} />
                </span>
              )}
            </Btn>
          </DialogActions>
        </form>
      </Dialog>

      <div className="pb-4 ">
        <Snackbar
          open={Boolean(showSuccessMsg)}
          autoHideDuration={6000}
          onClose={() => setShowSuccessMsg(false)}
        >
          <Alert
            onClose={() => setShowSuccessMsg(false)}
            severity="success"
            sx={{ width: '100%' }}
          >
            {showSuccessMsg}
          </Alert>
        </Snackbar>
      </div>
      {showDeleteDialog && (
        <ConfirmationDialog
          isOpen={showDeleteDialog}
          title={'Delete Category'}
          text="Are you sure you want to delete this Category ?"
          onReject={() => setShowDeleteDialog(false)}
          onResolve={() => deleteCategory()}
          actionBtn={t('common.delete')}
        />
      )}
    </div>
  );
};

export default CategoryPage;
