import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Alert, Button, Grid, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { ACCOUNTDETAIL } from "Constants/QueriesKeys";
import { http } from "Utils/Http/Http";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import moment from "moment";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Countries } from "Constants/CountryCodes";

const styles = {
  tableSize: {
    height: 650,
    width: "100%",
  },
  title: {
    color: "#797979",
    fontSize: "14px",
    width: "150px",
    display: "inline-block",
  },
  subTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "16px",
  },
};

// Component goes here
const AccountDetailPage = () => {
  const { accountId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedData, setSelectedData] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  //   const accountDetailQuery = useQuery([ACCOUNTDETAIL, { accountId }], () =>
  //     http.get(`/tenants/${accountId}`, {}).then(({ data }) => data)
  //   );

  //   if (accountDetailQuery.isLoading) {
  //     return <ResourceListSkeleton hasBreadcrumbs />;
  //   }

  useEffect(() => {
    getData();
  }, [accountId]);

  const getData = () => {
    try {
      http.get(`/tenant-register/${accountId}`).then(({ data }) => {
        // let obj = data?.data.find((f) => f.id === accountId);
        // console.log(ad);
        let code = Countries.find(
          (c) => c.Iso2 === data?.data?.phone_number_code
        ).Dial;
        setSelectedData(data?.data);
        setCountryCode(code);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const approveAccount = () => {
    try {
      http.post(`/tenant-approve/${selectedData?.id}`).then(({ data }) => {
        getData();
        setShowSuccessMsg("Account Approved Successfully");
        history.push("/app/accounts");
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid xs={12} style={{ minHeight: "600px" }}>
      <h3 className="mb-4">
        <ArrowBackIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/app/accounts");
          }}
        />{" "}
        &nbsp; Company Details
      </h3>
      <Card variant="outlined" className={"mt-5"}>
        {selectedData && (
          <div className={"m-5"}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={12} lg={12} xl={12} xs={12} className={"mb-1"}>
                <div className="flex justify-between">
                  <Typography sx={styles.title}>
                    {t("account.accOwner")}
                  </Typography>
                  <div>
                    <label style={styles.title}>Application Status</label>
                    <Chip
                      label={selectedData?.approved ? "Approved" : "Pending"}
                      color={!selectedData?.approved ? "warning" : "success"}
                      variant="contained"
                      size="small"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item md={12} lg={12} xl={12} xs={12}>
                <Typography sx={styles.subTitle}>
                  {selectedData?.name}
                </Typography>
              </Grid>
              <Grid item md={12} lg={12} xl={12} xs={12}>
                <span style={styles.title}>Phone No</span>
                <span
                  style={styles.subTitle}
                >{`+${countryCode}-${selectedData?.phone_number}`}</span>
              </Grid>
              <Grid item md={12} lg={12} xl={12} xs={12}>
                <span style={styles.title}>Email ID</span>
                <span style={styles.subTitle}>{selectedData?.email}</span>
              </Grid>
              <Grid item md={12} lg={12} xl={12} xs={12}>
                <span style={styles.title}>Company Name</span>
                <span style={styles.subTitle}>
                  {selectedData?.company_name}
                </span>
              </Grid>
              <Grid item md={12} lg={12} xl={12} xs={12}>
                <span style={styles.title}>Company ID</span>
                <span style={styles.subTitle}>{selectedData?.id}</span>
              </Grid>
              <Grid item md={12} lg={12} xl={12} xs={12}>
                <span style={styles.title}>Created At</span>
                <span style={styles.subTitle}>
                  {moment(selectedData?.created_at).format("dddd, D MMM YYYY")}
                </span>
              </Grid>
            </Grid>
          </div>
        )}
      </Card>
      <div className="mt-5">
        {/* <Button
          variant="outlined"
          color="primary"
          style={{ width: "200px", textTransform: "capitalize" }}
          onClick={() => {
            history.push("/app/accounts");
          }}
        >
          Decline
        </Button> */}
        <Button
          variant="contained"
          disabled={selectedData?.approved}
          color="primary"
          style={{
            width: "200px",
            // margin: "0 30px",
            textTransform: "capitalize",
          }}
          onClick={approveAccount}
        >
          Approve
        </Button>
      </div>
      {/* <ResourcesList
                BeforeHeader={
                    <Breadcrumbs className="mb-4">
                        <Link component={NavLink} to="/app/accounts">
                            {t('account.title')}
                        </Link>
                        <Typography color="textPrimary"># {accountDetailQuery?.data?.data?.company_name}</Typography>
                    </Breadcrumbs>
                }
                Section={
                    <div className="mt-8">
                        <Card variant="outlined">
                            <div className={'m-5'}>
                                <Grid container alignItems='center' spacing={1}>
                                    <Grid item md={12} lg={6} xl={6} xs={12}>
                                        <Typography sx={styles.title}>Company Name</Typography>
                                        <Typography sx={styles.subTitle}>{accountDetailQuery?.data?.data?.company_name}</Typography>
                                    </Grid>
                                    <Grid item md={12} lg={6} xl={6} xs={12}>
                                        <Typography sx={styles.title}>{t('account.status')} : <Chip label={accountDetailQuery?.data?.data?.subscription?.name} color={!accountDetailQuery?.data?.data?.subscription?.id ?"primary":"success"} variant="outlined" /></Typography>
                                    </Grid>
                                    <Grid item md={12} lg={6} xl={6} xs={12}>
                                        <Typography sx={styles.title}>{t('account.companyId')} : {accountDetailQuery?.data?.data?.id}</Typography>
                                    </Grid>
                                    <Grid item md={12} lg={6} xl={6} xs={12}>
                                        <Typography sx={styles.title}>{t('account.creationDate')} : {accountDetailQuery?.data?.data?.create_at}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Card>
                        <Card variant="outlined" className={'mt-5'}>
                            <div className={'m-5'}>
                                <Grid container alignItems='center' spacing={1}>
                                    <Grid item md={12} lg={12} xl={12} xs={12} className={'mb-1'}>
                                        <Typography sx={styles.title}>{t('account.accOwner')}</Typography>
                                    </Grid>
                                    <Grid item md={12} lg={12} xl={12} xs={12}>
                                        <Typography sx={styles.subTitle}>{accountDetailQuery?.data?.data?.owner?.name}</Typography>
                                    </Grid>
                                    <Grid item md={12} lg={412} xl={12} xs={12}>
                                        <Typography sx={styles.title}>{accountDetailQuery?.data?.data?.owner?.email}</Typography>
                                    </Grid>
                                    <Grid item md={12} lg={4} xl={4} xs={12}>
                                        <Typography sx={styles.title}>{accountDetailQuery?.data?.data?.owner?.phone_number}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Card>
                        <Card variant="outlined" className={'mt-5'}>
                            <div className={'m-5'}>
                                <Grid container alignItems='center' spacing={1} className={'mb-3'}>
                                    <Grid item md={6} lg={4} xl={4} xs={12}>
                                        <Typography sx={styles.title}>{t('account.no_community')}</Typography>
                                        <Typography sx={styles.subTitle}>{accountDetailQuery?.data?.data?.communities_count}</Typography>
                                    </Grid>
                                    <Grid item md={6} lg={4} xl={4} xs={12}>
                                        <Typography sx={styles.title}>{t('account.no_building')}</Typography>
                                        <Typography sx={styles.subTitle}>{accountDetailQuery?.data?.data?.buildings_count}</Typography>
                                    </Grid>
                                    <Grid item md={6} lg={4} xl={4} xs={12}>
                                        <Typography sx={styles.title}>{t('account.no_runit')}</Typography>
                                        <Typography sx={styles.subTitle}>{accountDetailQuery?.data?.data?.residential_units_count}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems='center' spacing={1} className={'mb-3'}>
                                    <Grid item md={6} lg={4} xl={4} xs={12}>
                                        <Typography sx={styles.title}>{t('account.no_cunit')}</Typography>
                                        <Typography sx={styles.subTitle}>{accountDetailQuery?.data?.data?.commercial_units_count}</Typography>
                                    </Grid>
                                    <Grid item md={6} lg={4} xl={4} xs={12}>
                                        <Typography sx={styles.title}>{t('account.no_admin')}</Typography>
                                        <Typography sx={styles.subTitle}>{accountDetailQuery?.data?.data?.admins_count}</Typography>
                                    </Grid>
                                    <Grid item md={6} lg={4} xl={4} xs={12}>
                                        <Typography sx={styles.title}>{t('account.no_manager')}</Typography>
                                        <Typography sx={styles.subTitle}>{accountDetailQuery?.data?.data?.managers_count}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems='center' spacing={1}>
                                    <Grid item md={6} lg={4} xl={4} xs={12}>
                                        <Typography sx={styles.title}>{t('account.no_tenant')}</Typography>
                                        <Typography sx={styles.subTitle}>{accountDetailQuery?.data?.data?.tenants_count}</Typography>
                                    </Grid>
                                    <Grid item md={8}>
                                        <Typography sx={styles.title}>{t('account.no_security')}</Typography>
                                        <Typography sx={styles.subTitle}>{accountDetailQuery?.data?.data?.security_count}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Card>
                        <Card variant="outlined" className={'mt-5'}>
                            <div className={'m-5'}>
                                <Grid container alignItems='center' spacing={1}>
                                    <Grid item md={6} lg={6} xl={6} xs={12} className={'mb-1'}>
                                        <Typography sx={styles.title}>{t('account.total_money_in')}</Typography>
                                        <Typography sx={styles.subTitle}>{accountDetailQuery?.data?.data?.total_money_in}</Typography>
                                    </Grid>
                                    <Grid item md={6} lg={6} xl={6} xs={12} className={'mb-1'}>
                                        <Typography sx={styles.title}>{t('account.total_money_out')}</Typography>
                                        <Typography sx={styles.subTitle}>{accountDetailQuery?.data?.data?.total_money_out}</Typography>
                                    </Grid>
                                    <Grid item md={12} lg={12} xl={12} xs={12} className={' mt-3'}>
                                        <Typography sx={styles.title} className={'mb-2'}>{t('account.total_money_pnl')}</Typography>
                                        <Grid container alignItems='center' spacing={1}>
                                        <Grid item md={4} lg={4} xl={4} xs={4} className={'mb-1'}>
                                            <Typography sx={styles.subTitle}>{accountDetailQuery?.data?.data?.profit_loss}</Typography>
                                            <Typography sx={styles.title}>{t('account.profit')}</Typography>
                                        </Grid>
                                        <Grid item md={4} lg={4} xl={4} xs={4} className={'mb-1'}>
                                            <Typography sx={styles.subTitle}>{accountDetailQuery?.data?.data?.profit_loss}</Typography>
                                            <Typography sx={styles.title}>{t('account.loss')}</Typography>
                                        </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </div>
                        </Card>
                    </div>
                }
            /> */}
      {showSuccessMsg ? (
        <div className="pb-4 ">
          <Alert severity="success">{showSuccessMsg}</Alert>
        </div>
      ) : null}
    </Grid>
  );
};

export default AccountDetailPage;
