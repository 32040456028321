import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AppLayout from "Layouts/AppLayout";
import SignInPage from "Pages/Auth/SignInPage";
import { NavLinks } from "Constants/NavLinks";
import { ConfigNavLinks } from "Constants/NavLinks";
import AccountsPage from "Pages/Accounts/AccountsPage";
import CategoryPage from "Pages/Category/CategoryPage";
import CategoryDetailPage from "Pages/Category/CategoryDetailPage";
import AccountDetailPage from "Pages/Accounts/AccountDetailPage";
import CreatePushNotification from "Pages/PushNotification/CreatePushNotification";
import AddAccount from "Pages/Accounts/AccountAddCU";

export default function App() {
  let { path } = useRouteMatch();
  console.log(path, "s");

  return (
    <AppLayout links={NavLinks} settings={ConfigNavLinks}>
      <Switch>
        <Route exact path={`${path}/accounts`} component={AccountsPage} />
        <Route exact path={`${path}/accounts/add`} component={AddAccount} />
        <Route
          exact
          path={`${path}/accounts/edit/:accountId`}
          component={AddAccount}
        />
        <Route
          exact
          path={`${path}/accounts/:accountId`}
          component={AccountDetailPage}
        />
        <Route
          exact
          path={`${path}/category/:accountId`}
          component={CategoryDetailPage}
        />
        <Route
          exact
          path={`${path}/pushnotification`}
          component={CreatePushNotification}
        />
        <Route exact path={`${path}/category`} component={CategoryPage} />
      </Switch>
    </AppLayout>
  );
}
