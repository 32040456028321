import React, { useEffect, useState } from 'react';
import { Alert, Button, Paper, InputAdornment, TextField } from '@mui/material';
import { Box } from '@mui/system';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { useQuery } from 'react-query';
import { ACCOUNTS } from 'Constants/QueriesKeys';
import { handleError, http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
  DataGrid,
  ResourceListSkeleton,
  IconBtnBase,
  ResourcesList,
  ConfirmationDialog,
} from 'Components';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const styles = {
  tableSize: {
    height: 650,
    width: '100%',
  },
};

// Component goes here
const AccountsPage = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [sortModel, setSortModel] = useState(undefined);
  const sort_item = sortModel?.[0]?.field?.includes('.')
    ? sortModel?.[0]?.field?.split('.')[1]
    : sortModel?.[0]?.field;
  const sort_type = sortModel?.[0]?.sort;
  const accountQuery = useQuery(
    [ACCOUNTS, { page, searchTerm, sort_item, sort_type }],
    () =>
      http
        .get(`/tenant-register`, {
          params: {
            page,
            search_item: searchTerm,
            sort_type,
            sort_item,
          },
        })
        .then(({ data }) => data),
    [page, searchTerm, sortModel?.[0]?.sort, sortModel?.[0]?.field]
  );

  const deleteAccount = () => {
    setShowDeleteDialog(false);
    setShowSuccessMsg('Account Deleted Successfully');
  };

  const columns = [
    {
      field: 'name',
      headerName: t('account.name'),
      minWidth: 200,
      editable: false,
    },
    {
      field: 'phone_number',
      headerName: t('account.phoneNumber'),
      minWidth: 200,
      editable: false,
    },
    {
      field: 'email',
      headerName: t('account.email'),
      minWidth: 200,
      editable: false,
    },
    {
      field: 'company_name',
      headerName: t('account.companyName'),
      minWidth: 200,
      editable: false,
    },
    {
      field: 'id',
      headerName: t('account.companyId'),
      minWidth: 200,
      editable: false,
    },
    // {
    //   field: "create_at",
    //   headerName: t("account.date"),
    //   minWidth: 170,
    //   editable: false,
    // },
    {
      field: 'subscription.name',
      headerName: t('account.status'),
      minWidth: 170,
      editable: false,
      renderCell: (row) => {
        return (
          <Chip
            label={row?.row?.approved ? 'Approved' : 'Pending'}
            color={!row?.row?.approved ? 'warning' : 'success'}
            variant="contained"
            size="small"
          />
        );
      },
    },
    {
      field: 'subscription.created_at',
      headerName: t('account.created_at'),
      minWidth: 170,
      editable: false,
      renderCell: (row) => {
        return moment(row?.row?.created_at).format('dddd, D MMM YYYY');
      },
    },
    // {
    //   field: "units_count",
    //   headerName: t("account.no_units"),
    //   minWidth: 150,
    //   editable: false,
    // },
    // {
    //   field: "users_count",
    //   headerName: t("account.no_users"),
    //   minWidth: 150,
    //   editable: false,
    // {moment(item?.start_date).format('dddd, D MMM YYYY')} to{' '}

    // },
    {
      field: 'action',
      headerName: t('account.actions'),
      minWidth: 120,
      editable: false,
      renderCell: (row) => {
        return (
          <div className="flex items-center">
            <IconBtnBase
              onClick={() => {
                handleViewTransactionButtonClick(row);
              }}
              size="large"
            >
              <VisibilityIcon />
            </IconBtnBase>
            {/* <IconBtnBase
              title={t("common.delete")}
              onClick={() => setShowDeleteDialog(true)}
              size="large"
            >
              <DeleteOutlineIcon />
            </IconBtnBase> */}
            <IconBtnBase
              title={t('common.edit')}
              onClick={() => {
                handleEdit(row);
              }}
              size="large"
              disabled={row?.row?.approved}
            >
              <EditIcon />
            </IconBtnBase>
          </div>
        );
      },
    },
  ];

  // Handlers
  const handleViewTransactionButtonClick = (row) => {
    history.push(`/app/accounts/${row?.id}`);
  };

  const handleEdit = (row) => {
    history.push(`/app/accounts/edit/${row?.id}`);
  };

  const handleSearch = (evt) => {
    setPage(1);
    setSearchTerm(evt.target.value);
  };

  // if (accountQuery.isLoading) {
  //   return <ResourceListSkeleton />;
  // }

  return (
    <div>
      <ResourcesList
        headerProps={{
          title: <p style={{ fontSize: '20px' }}>{t('account.title')}</p>,
          EndSide: (
            <div className="flex">
              <TextField
                name="search"
                label="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                value={searchTerm}
                onChange={handleSearch}
              />
              <IconBtnBase
                onClick={() => {
                  accountQuery.refetch();
                }}
                size="large"
              >
                <RefreshIcon />
              </IconBtnBase>
              <Button
                size="small"
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => history.push(`/app/accounts/add`)}
              >
                add New
              </Button>
            </div>
          ),
        }}
        Section={
          <div className="mt-8">
            <Box sx={styles.tableSize}>
              <Paper>
                <div style={{ height: 600, width: '100%', overflowY: 'auto' }}>
                  <DataGrid
                    pagination
                    rowCount={accountQuery.data?.meta?.total || 0}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                      setPage(newPage + 1);
                    }}
                    loading={accountQuery.isFetching}
                    rows={accountQuery.data?.data || []}
                    columns={columns}
                    pageSize={accountQuery.data?.meta?.per_page || 0}
                    page={page - 1 || 0}
                    sortModel={sortModel}
                    sortingMode="server"
                    onSortModelChange={(e) => {
                      if (JSON.stringify(e) !== JSON.stringify(sortModel)) {
                        console.log(e, sortModel);
                        setSortModel(e);
                      }
                    }}
                  />
                </div>
              </Paper>
            </Box>
          </div>
        }
      />
      {showSuccessMsg ? (
        <div className="pb-4 ">
          <Alert severity="success">{showSuccessMsg}</Alert>
        </div>
      ) : null}
      {showDeleteDialog && (
        <ConfirmationDialog
          isOpen={showDeleteDialog}
          title={'Delete Account'}
          text="Are you sure you want to delete this Account ?"
          onReject={() => setShowDeleteDialog(false)}
          onResolve={() => deleteAccount()}
          actionBtn={t('common.delete')}
        />
      )}
    </div>
  );
};

export default AccountsPage;
